import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import emojiRegex from 'emoji-regex';
import { useForm } from 'react-hook-form';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useQuery, useQueryClient } from 'react-query';
import TextareaAutosize from 'react-textarea-autosize';
import * as yup from 'yup';

import { forwardRef } from 'react';
import { useAppContext } from '../../context/AppContext';
import { FETCH_ORDER_KEY, useOrderContext } from '../../context/OrderContext';
import useMutationWithError from '../../hooks/useMutationWithError';
import { GiftMessage } from '../../types/api';
import useAnalytics from '../../utils/analytics';
import apiClient from '../../utils/api';
import { omsPublic } from '../../utils/apiOms';
import Button from '../Buttons/Button';
import { ChevronIcon } from '../Icons';
import Toggle from '../Toggle/Toggle';

interface GiftMessageFormProps {
    defaultOpen?: boolean;
    giftMessage?: GiftMessage;
    onConfirm: () => void;
}

type FormValues = {
    title: string;
    message: string;
};

const MAX_CHARS_TITLE = 20;
const MAX_CHARS_MSG = 500;

const validationErrors = defineMessages({
    message: { id: 'gift_message.fields.message.error' },
    title: { id: 'gift_message.fields.title.error' },
});

const schema = yup.object().shape({
    message: yup.string().test('emoji', value => !value?.match(emojiRegex())),
    title: yup.string().test('emoji', value => !value?.match(emojiRegex())),
});

const GiftMessageForm = forwardRef(({ defaultOpen = false, giftMessage, onConfirm }: GiftMessageFormProps, ref) => {
    const queryClient = useQueryClient();
    const { brand, orderId, site } = useAppContext();
    const { order } = useOrderContext();
    const { onGiftClick } = useAnalytics();

    const { formState, handleSubmit, register, reset, watch } = useForm<FormValues>({
        mode: 'onChange',
        shouldFocusError: true,
        resolver: yupResolver(schema),
    });

    const { data: evergreenMedia } = useQuery('evergreenMedia-gift-message', () =>
        apiClient.getEvergreenMedia('gift_message', {
            site: site.code!,
            brand,
        })
    );
    const mediaUrl = evergreenMedia?.data.mediaUrl;
    const addGiftMessage = useMutationWithError(
        (data: FormValues) =>
            omsPublic
                .addGiftMessage(orderId, {
                    title: data.title,
                    message: data.message,
                })
                .then(data => {
                    reset();
                    return data;
                }),
        {
            onSuccess: data => {
                if (data) {
                    queryClient.invalidateQueries(FETCH_ORDER_KEY);
                }
                onConfirm();
            },
        }
    );

    const onSubmit = (data: FormValues) => addGiftMessage.mutate(data);

    const title = watch('title');
    const message = watch('message');

    const remaingingCharsTitle = MAX_CHARS_TITLE - (title?.length || 0);
    const remaingingCharsMsg = MAX_CHARS_MSG - (message?.length || 0);

    return (
        <form id="gift-form" className="u-mt-xs-sm" onSubmit={handleSubmit(onSubmit)}>
            <div className="c-box c-box--outline u-mt-sm-bis u-mt-md@md u-mt-md@md-plus">
                <div className="c-box__inner">
                    <Toggle
                        className="c-disclosure--arrow"
                        defaultOpen={defaultOpen}
                        label={
                            <>
                                <span>
                                    <span className="c-disclosure__title u-block@sm u-text-xl-bis-plus">
                                        <FormattedMessage id="gift_message.title" />
                                    </span>{' '}
                                    <FormattedMessage id="gift_message.description" />
                                </span>
                                <i className="c-icon c-disclosure__icon">
                                    <ChevronIcon />
                                </i>
                            </>
                        }
                        onToggle={() => {
                            onGiftClick(order!);
                        }}
                    >
                        <div className="o-grid o-grid--gutter-md u-mt-sm">
                            <div
                                className={classNames('c-form-group o-grid__col', {
                                    'o-grid__col--16 o-grid__col--24@sm': mediaUrl,
                                    'o-grid__col--24': !mediaUrl,
                                })}
                            >
                                <div className="c-form-group">
                                    <label htmlFor="gift-title" className="c-label c-label--gift-title">
                                        <FormattedMessage id="gift_message.fields.title.label" />
                                        <span className="c-label__additional u-ml-xxs-bis">
                                            {` ${remaingingCharsTitle} `}
                                            <FormattedMessage id="gift_message.remaining" />
                                        </span>
                                    </label>
                                    <TextareaAutosize
                                        id="gift-title"
                                        className="c-field c-field--gift-title"
                                        defaultValue={giftMessage?.title || ''}
                                        maxLength={MAX_CHARS_TITLE}
                                        {...register('title')}
                                    />
                                    {formState.errors.title && (
                                        <p className="u-mt-xs u-color-error">
                                            <i>
                                                <FormattedMessage id={validationErrors.title.id} />
                                            </i>
                                        </p>
                                    )}
                                </div>
                                <div className="c-form-group">
                                    <label htmlFor="gift-message" className="c-label c-label--gift-message">
                                        <FormattedMessage id="gift_message.fields.message.label" />
                                        <span className="c-label__additional u-ml-xxs-bis">
                                            {` ${remaingingCharsMsg} `}
                                            <FormattedMessage id="gift_message.remaining" />
                                        </span>
                                    </label>
                                    <TextareaAutosize
                                        id="gift-message"
                                        className="c-field c-field--gift-message"
                                        defaultValue={giftMessage?.message || ''}
                                        maxLength={MAX_CHARS_MSG}
                                        minRows={4}
                                        {...register('message')}
                                    />
                                    {formState.errors.message && (
                                        <p className="u-mt-xs u-color-error">
                                            <i>
                                                <FormattedMessage id={validationErrors.message.id} />
                                            </i>
                                        </p>
                                    )}
                                </div>

                                <Button
                                    type="submit"
                                    className="c-btn c-btn--senary c-btn--loader u-w-100 u-mt-sm-bis"
                                    isLoading={addGiftMessage.isLoading}
                                    disabled={!formState.isValid}
                                >
                                    <FormattedMessage id="actions.add" />
                                </Button>
                            </div>
                            {mediaUrl && (
                                <div className="o-grid__col o-grid__col--24@sm o-grid__col--8">
                                    <img src={mediaUrl} alt="Gift message" />
                                </div>
                            )}
                        </div>
                    </Toggle>
                </div>
            </div>
        </form>
    );
});

export default GiftMessageForm;
